import { createApi } from '@reduxjs/toolkit/query/react';
import { Environment } from 'environment';
import {
    AIResponse,
    CVClassification,
    CVSummary,
    RecruitmentQuestion,
    RecruitmentQuestions,
    RecruitmentTranscription
} from 'models/ai';
import axiosBaseQuery from 'store/axiosBaseQuery';

// const local = true;
// const baseUrlOverride = local ? 'https://localhost:8003/api/v3' : undefined;

const baseUrlOverride = Environment.apiUrl.replace('v1', 'v3');

export const aiApiService = createApi({
    reducerPath: 'aiApiService',
    baseQuery: axiosBaseQuery({ basePath: '/ai' }),
    endpoints: (builder) => ({
        summarizeCV: builder.mutation<
            CVSummary,
            { position: string; level: string; skills: string[]; question?: string; file: File }
        >({
            query: ({ position, level, skills, question, file }) => {
                const formData = new FormData();
                formData.append('file', file);

                return {
                    baseUrlOverride,
                    url: `/cv/summary/${position}/${level}`,
                    params: {
                        question,
                        skills: skills.join(',')
                    },
                    method: 'post',
                    data: formData
                };
            }
        }),
        classifyCV: builder.mutation<CVClassification, { position: string; level: string; skills: string[]; cv: File }>({
            query: ({ position, level, skills, cv }) => {
                const formData = new FormData();
                formData.append('file', cv);

                return {
                    // baseUrlOverride,
                    url: `/cv/summary/classification/${position}/${level}`,
                    method: 'post',
                    params: {
                        list_of_skills: skills.join(',')
                    },
                    data: formData
                };
            }
        }),
        generateQuestions: builder.query<
            RecruitmentQuestion[],
            { position: string; level: string; skills: string[]; quantity: number }
        >({
            query: ({ position, level, skills, quantity }) => ({
                baseUrlOverride,
                url: `/questions/generate/${position}/${level}`,
                method: 'get',
                params: {
                    quantity,
                    skills: skills.join(',')
                }
            }),
            transformResponse: (response: RecruitmentQuestions) => {
                return response.qa.map((q) => ({
                    question: q.question,
                    answer: q.answers ? q.answers.join(' ') : q.answer
                }));
            }
        }),
        generateExercise: builder.query<string, { position: string; level: string; skills: string[] }>({
            query: ({ position, level, skills }) => ({
                baseUrlOverride,
                url: `/exercises/generate/${position}/${level}`,
                method: 'get',
                params: {
                    skills: skills.join(',')
                }
            }),
            transformResponse: (response: AIResponse) => {
                return response.content;
            }
        }),
        generateSpreadsheetExercises: builder.query<string, { level: string; quantity: number }>({
            query: ({ level, quantity }) => ({
                baseUrlOverride,
                url: `/wb_exercises/generate/${level}`,
                method: 'get',
                params: {
                    q_number: quantity
                }
            }),
            transformResponse: (response: AIResponse) => {
                return response.content;
            }
        }),
        transcribeInterview: builder.mutation<
            RecruitmentTranscription,
            { position: string; level: string; skills: string[]; recording: File; questions: File }
        >({
            query: ({ position, level, skills, recording, questions }) => {
                const formData = new FormData();
                formData.append('file', recording);
                formData.append('questions', questions);

                return {
                    // baseUrlOverride,
                    url: `/transcript/summary/${position}/${level}`,
                    method: 'post',
                    params: {
                        list_of_skills: skills.join(',')
                    },
                    data: formData
                };
            },
            transformResponse: (response: RecruitmentTranscription) => {
                return {
                    ...response,
                    classification_skills: response.classification_skills
                        .replaceAll('Years of experience', 'estimated years of experience')
                        .replaceAll('years_of_experience', 'estimated years of experience')
                };
            }
        })
    })
});

export const {
    useSummarizeCVMutation,
    useLazyGenerateQuestionsQuery,
    useLazyGenerateExerciseQuery,
    useLazyGenerateSpreadsheetExercisesQuery,
    useTranscribeInterviewMutation,
    useClassifyCVMutation
} = aiApiService;
